import React, { useState, useEffect } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css';
import {  StyledFormButton } from '../Styles';
import { isTrue } from "../apiCredentials";
import { useGetClientId } from "../../hooks/useGetUserClientId";

function IncidentForm() {
  const CLIENTID = useGetClientId();
  const [formData, setFormData] = useState({
    empId: '',
    nombre: '',
    fecini: '',
    fecfin: '',
    inciden: ''
  });

  useEffect(() => {
    M.AutoInit(); // Initialize all Materialize components
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Clear the nombre field if empId is empty
    if (name === 'empId' && value === '') {
      setFormData({
        ...formData,
        empId: '',
        nombre: ''
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const fetchEmployeeName = async (empId) => {
    const URL_STRING = `https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/empleados/${CLIENTID}`;
    if(empId.length >= 4) {
      try {
        const response = await fetch(URL_STRING);
        const employees = await response.json();
        const employee = employees.find(emp => emp.empnum === empId);

        if (employee) {
          setFormData(prevData => ({
            ...prevData,
            nombre: employee.paterno + ' ' + employee.materno + ' ' + employee.nombre
          }));
        } else {
          setFormData(prevData => ({
            ...prevData,
            nombre: 'No se encontró el ID'
          }));
        }
      } catch (error) {
        console.error('Error fetching employee name:', error);
        setFormData(prevData => ({
          ...prevData,
          nombre: 'Error fetching name'
        }));
      }
    }
  };

  useEffect(() => {
    if (formData.empId) {
      fetchEmployeeName(formData.empId);
    }
  }, [formData.empId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const incInfo = {
      empId: formData.empId,
      fecini: formData.fecini,
      fecfin: formData.fecfin,
      inciden: formData.inciden
    };

    await createIncidentsWA(incInfo);
  };

  const createIncidentsWA = async (incInfo) => {
    const URL_STRING = "https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/incidencias";
    const record = {
      numempleado: incInfo.empId,
      client_id: CLIENTID,
      fechaDeInicio: incInfo.fecini,
      fechaDeFin: incInfo.fecfin,
      tipoIncidencia: incInfo.inciden
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: "no-cors",
      body: JSON.stringify(record)
    };

    try {
      await fetch(URL_STRING, options);
      M.toast({ html: 'Incidencia enviada exitosamente!', classes: 'green' }); // Success toast
      
      // Clear form data after successful submission
      setFormData({
        empId: '',
        nombre: '',
        fecini: '',
        fecfin: '',
        inciden: ''
      });
    } catch (error) {
      console.error('Error:', error);
      M.toast({ html: 'Error al enviar la incidencia.', classes: 'red' }); // Error toast
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col s3'>
            <div className="input-field">
              <input
                id="empId"
                type="text"
                name="empId"
                value={formData.empId}
                onChange={handleInputChange}
              />
              <label htmlFor="empId" className={formData.empId ? 'active' : ''}>No Empleado</label>
            </div>
          </div>
          <div className='col s6'>
            <div className="input-field">
              <input
                id="nombre"
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleInputChange}
                disabled // Disabling to prevent manual input
              />
              <label htmlFor="nombre" className={formData.nombre ? 'active' : ''}>Nombre</label>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className="input-field col s3">
            <input
              id="fecini"
              type="date"
              name="fecini"
              value={formData.fecini}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="fecini" className="active">Fecha de Inicio</label>
          </div>
          <div className="input-field col s3">
            <input
              id="fecfin"
              type="date"
              name="fecfin"
              value={formData.fecfin}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="fecfin" className="active">Fecha de Fin</label>
          </div>
        </div>
        <div className='row'>
          <div className="input-field col s5">
            <select
              id="inciden"
              name="inciden"
              value={formData.inciden}
              onChange={handleInputChange}
              className="browser-default"
              required
            >
              <option value="" disabled>Selecciona una opción</option>
              <option value="V">Vacaciones</option>
              <option value="INC">Incapacidad</option>
              <option value="PC/G">Permiso con Goce</option>
              <option value="PS/G">Permiso sin Goce</option>
              <option value="D">Descanso</option>
              <option value="FJ">Falta Justificada</option>
              <option value="PH">Pago Horas</option>
              <option value="AA">Acta Administrativa</option>
              <option value="FI">Falta Injustificada</option>
              <option value="CAP">Capacitación</option>
              <option value="C">Curva</option>
              <option value="AUS">Posible Baja</option>
              <option value="HO">Home Office</option>
            </select>
            <label htmlFor="inciden" className="active">Tipo de Incidencia</label>
          </div>
          <div style={{ padding: "13px" }}>
            {isTrue ? (
              <StyledFormButton
                type='submit'
              >Insertar
              </StyledFormButton>
            ) : (
              <StyledFormButton
                type='button'
                disabled
              >Solo Lectura
              </StyledFormButton>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default IncidentForm;
