import React, { useEffect, useState } from "react";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator.min.css";
import { DateTime } from "luxon";
import { useGetClientId } from "../../hooks/useGetUserClientId"; // Assuming you have this custom hook

const CountFTableWeek = () => {
  const CLIENTID = useGetClientId();
  const [table, setTable] = useState(null);
  const [loading, setLoading] = useState(true);

  // Calculate dates for the last seven days
  const fecha_fin = DateTime.now().toFormat("yyyy-MM-dd");
  const fecha_inicio = DateTime.now().minus({ days: 7 }).toFormat("yyyy-MM-dd");

  const URL_GET = `https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/asistencias/${fecha_inicio}/${fecha_fin}/${CLIENTID}`;

  const fetchData = async () => {
    try {
      const response = await fetch(URL_GET);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();

      // Process data to count "F" in horas for each employee
      const processedData = data.reduce((acc, item) => {
        const existingEmployee = acc.find(emp => emp.employee_number === item.employee_number);
        if (existingEmployee) {
          existingEmployee.f_count += item.hora === "F" ? 1 : 0;
        } else {
          acc.push({
            employee_number: item.employee_number,
            nombre: item.nombre,
            f_count: item.hora === "F" ? 1 : 0,
          });
        }
        return acc;
      }, []);

      return processedData;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const initializeTable = (data) => {
    if (table) {
      table.destroy();
    }

    const newTable = new Tabulator("#f-count-table", {
      data,
      layout: "fitColumns",
      pagination: "local",
      paginationSize: 20,
      initialSort: [
        { column: "f_count", dir: "desc" }, // Sort by "Count of 'F'" descending by default
      ],
      columns: [
        { title: "", field: "rownum", width: 30 },
        { title: "Emp_Id", field: "employee_number", width: 60 },
        { title: "Nombre", field: "nombre", width: 150 },
        { title: "Faltas", field: "f_count", hozAlign: "center", width: 60 },
      ],
    });

    setTable(newTable);
  };

  useEffect(() => {
    const loadTable = async () => {
      setLoading(true);

      // Fetch and process the data
      const data = await fetchData();

      // Wait until the DOM is ready before initializing Tabulator
      if (document.getElementById("f-count-table")) {
        // Initialize the table with the processed data
        initializeTable(data);
        setLoading(false);
      }
    };

    loadTable();
  }, []); // Fetch data and initialize table on component mount

  return (
    <>
      {loading && (
        <div className="progress">
          <div className="indeterminate"></div>
        </div>
      )}
      <div id="f-count-table"></div>
    </>
  );
};

export default CountFTableWeek;
