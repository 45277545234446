import Navbar from "../components/NavbarUsers"
import { StyledSubtitle, colors } from "../components/Styles"
//import UsersWithConsecutiveF from "../components/incidencias/ConsecutiveF"
//import ExcelUpload from "../components/incidencias/IncidenciasFines"
import FaltasTabla from "../components/incidencias/TablaFaltas"

import IncidentForm from "../components/incidencias/incidenciasFecha"




const IncidenciasPage = () => {
    return(
        <div>
            <Navbar/> 
            <div className="container">
            <StyledSubtitle color={colors.theme} size={24}>
            Carga de Incidencias por dia
            </StyledSubtitle>
                <div>  <FaltasTabla /></div>
            <StyledSubtitle color={colors.theme} size={24}>
            Crear Incidencia por fecha
            </StyledSubtitle>
                <div><IncidentForm /></div>
            </div>

  
           
            
            
          
          

        </div>
    )

}

export default IncidenciasPage